import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"



const Horlogeries = () => {
  return (
    <>
      <Layout>
        <SEO title="Horlogerie"/>

        <div className="slideshow">
          <div className="usp">
            <div className="usp-txt-header">Montres & Accessoires</div>
            <div className="usp-txt">Montres & Accessoires représentées dans nos boutiques</div>
          </div>
        </div>
        <div className="border-gold-bottom"></div>

        <h3>Montres</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/horlogerie_yvan_monnet" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Yvan Monnet</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_grovana" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Grovana</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_maserati" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Maserati</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/horlogerie_gvchiani" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Gvchiani Genève</div>
              </Link>
            </div>


          </div>

        </section>

        <h3>Les Bracelets</h3>

        <section className="section-flex-04">

          <div className="section-flex-03">

            <div className="gold-box-small">
              <Link to="/bracelets_everestbands" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Bracelets Everestbands</div>
              </Link>
            </div>

            <h3>Les Remontoirs pour montres automatiques & Rangements</h3>

            <div className="gold-box-small">
              <Link to="/swisskubik" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Swisskubik</div>
              </Link>
            </div>

            <div className="gold-box-small">
              <Link to="/scatola_del_tempo" className="gold-box-small-text" style={{textDecoration:'none'}}>
                <div className="gold-box-small-img-Bagues">Scatola del Tempo</div>
              </Link>
            </div>

          </div>

        </section>
      </Layout>
    </>
  )
}

export default Horlogeries
